import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import DataTable from 'datatables.net-dt';


import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpLogrEntity from '../entity';
import UtilsEntity from "../../../../utils/entity";

export default class ErpLogList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "erp/logs"
        this.tableElem = jQuery('.datatables-users')

        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return ErpLogrEntity.getEntityData(elem)
    }

    bindListeners() {

    }


    createTable() {
        this.datatable = new DataTable(".datatables-users", {
            initComplete: async () => {
            },
            ajax: {
                url: `/api/v1/erp/logs`,
                dataType: 'json',
                beforeSend: () => {
                    this.tableElem.closest(".card").find(".card-datatable").addClass("d-none")
                    this.tableElem.closest(".card").find(".loading-body").removeClass("d-none")
                },

                complete: () => {
                    this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                    this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
                },
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; columns: any; }) => {
                    return {
                        length: params.length,
                        draw: params.draw,
                        start: params.start,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : [],
                        columns: params.columns
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if (data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            order: [[0, 'desc']],
            ordering: false,
            serverSide: true,
            pageLength: 50,
            columns: [
                {data: 'product.name'},
                {data: 'serial'},
                {data: 'action'},
                {data: 'uuid'},
                {data: 'user.name'},
                {data: 'created_at'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data ? data : ''}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${Utils.translate(`erp.log.status.${data}`)}`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.action === "INPUT") {
                            str = `Lager: <b>${full.action_data.new.warehouse.name}</b><br />
                                   Lagerplatz: <b>${full.action_data.new.warehouse_location.name}</b>`
                            if (full.action_data.new.warehouse_location_pallet) {
                                str += `<br />Palette: <b>${full.action_data.new.warehouse_location_pallet.name}</b>`
                            }
                        } else if (full.action === "OUTPUT") {
                            str = `Lager: <b>${full.action_data.old.warehouse.name}</b><br />
                                   Lagerplatz: <b>${full.action_data.old.warehouse_location.name}</b>`
                            if (full.action_data.old.warehouse_location_pallet) {
                                str += `<br />Palette: <b>${full.action_data.old.warehouse_location_pallet.name}</b>`
                            }
                        } else if (full.action === "MOVE") {
                            str = `Lager: <b>${full.action_data.old.warehouse.name} -> ${full.action_data.new.warehouse.name}</b><br />
                                   Lagerplatz: <b>${full.action_data.old.warehouse_location.name} -> ${full.action_data.new.warehouse_location.name}</b>`
                            if (full.action_data.new.warehouse_location_pallet || full.action_data.old.warehouse_location_pallet) {
                                str += `<br />Palette: <b>${full.action_data.old.warehouse_location_pallet.name} -> ${full.action_data.new.warehouse_location_pallet.name}</b>`
                            }
                        } else if (full.action === "ORDERED" || full.action === "SHIPPED") {
                            str = `Bestellung: <b>${full.action_data.order.orderNumber}</b>`
                        }
                        if (Object.keys(full.payload).length > 0) {
                            str += `<hr />`
                            Object.keys(full.payload).forEach((payloadKey: string) => {
                                str += `${payloadKey}: <b>${full.payload[payloadKey]}</b><br />`
                            })
                        }
                        return str;
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}`;
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${new Date(Date.parse(data)).toLocaleString()}`;
                    },
                },
            ],
            processing: true,
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: []
        });
    }
}